import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { useStyles } from "../common/appStyles";
import { SelectControl, selectControlTester } from '../controls';
import MediaCaptureControl from '../controls/media-capture/MediaCaptureControl';
import mediaCaptureControlTester from '../controls/media-capture/mediaCaptureControlTester';
import ImageCaptureControl from '../controls/image-capture/ImageCaptureControl';
import imageCaptureControlTester from '../controls/image-capture/imageCaptureControlTester';
import PhotoCaptureControl from '../controls/photo-capture/PhotoCaptureControl';
import photoCaptureControlTester from '../controls/photo-capture/photoCaptureControlTester';
import CalculatedFieldControl from '../controls/calculated-field/CalculatedFieldControl';
import calculatedFieldControlTester from '../controls/calculated-field/calculatedFieldControlTester';
import AccordionGroupControl from '../controls/accordion-group/AccordionGroupControl';
import AccordionGroupControlTester from '../controls/accordion-group/AccordionGroupControlTester';
import TextHeaderControl from '../controls/text-header/TextHeaderControl';
import TextHeaderControlTester from '../controls/text-header/TextHeaderControlTester';
import { PublicAddEditFormEntryProps } from '../types/forms';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import { showSnackbar } from '../services/snackbar.service';
import { ShoFormLoader } from './main-layout/ShoFormLoader';
import SeparatorControl from '../controls/separator/SeparatorControl';
import SeparatorControlTester from '../controls/separator/SeparatorControlTester';
import { SubmitSurveyResponse } from '../services/form-service';

const renderers = [
    ...materialRenderers,
    { tester: selectControlTester, renderer: SelectControl },
    { tester: mediaCaptureControlTester, renderer: MediaCaptureControl },
    { tester: imageCaptureControlTester, renderer: ImageCaptureControl },
    { tester: photoCaptureControlTester, renderer: PhotoCaptureControl },
    { tester: calculatedFieldControlTester, renderer: CalculatedFieldControl },
    { tester: AccordionGroupControlTester, renderer: AccordionGroupControl },
    { tester: TextHeaderControlTester, renderer: TextHeaderControl },
    { tester: SeparatorControlTester, renderer: SeparatorControl },
];

const PublicAddEditFormEntry: React.FC<PublicAddEditFormEntryProps> = (props) => {
    const { showDialog, form, formData, handleClose, handleSubmitSuccess, token } = props;
    const classes = useStyles();
    const [initialData, setInitialData] = useState<any>(null);
    const [showJsonForms, setShowJsonForms] = useState<boolean>(false);
    const [schema, setSchema] = useState<JsonSchema>({});
    const [uischema, setUiSchema] = useState<UISchemaElement>();
    const [data, setData] = useState<any>(null);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (formIsValid()) {
            await SubmitSurveyResponse(token, data)
            handleSubmitSuccess();
        }
    };

    const getFormSchemaDetail = async () => {
        const schemaObj = JSON.parse(form.formSchemaJson);
        setSchema(schemaObj);
        const layoutSchemaObj = JSON.parse(form.formLayoutSchemaJson);
        setUiSchema(layoutSchemaObj);
    };

    const getFormDataDetail = async () => {
        try {
            setInitialData(JSON.parse(formData.formDataJson));
            setData(JSON.parse(formData.formDataJson));
        } catch (error) {
            console.error('Error fetching data')
        }
    }

    type ValidationError = {
        instancePath: string;
        schemaPath: string;
        keyword: string;
        params: {
            missingProperty: string;
        };
        message: string;
        schema: string[];
        parentSchema: object;
        data: object;
    };

    const logMissingProperties = (validationErrors: ValidationError[]): boolean => {
        let missingPropertyErrorsPresent = false;
        validationErrors.forEach(error => {
            if (error.params && error.params.missingProperty) {
                showSnackbar(`Field "${error.params.missingProperty}" is required. Please fill it in.`, 'error', 5000);
                missingPropertyErrorsPresent = true;
            }
        });
        return missingPropertyErrorsPresent;
    };

    const formIsValid = (): boolean => {
        if (!data) {
            showSnackbar(`Please fill in the form before saving.`, 'error', 5000);
            return false;
        }
        if (errors.length > 0) {
            let missingPropertyErrorsPresent = logMissingProperties(errors);
            if (missingPropertyErrorsPresent) {
                return false;
            }
        }
        return true;
    }

    const saveFormData = async () => {
        try {
            if (!formIsValid) {
                return;
            }
            setLoading(true);
            const fields = Object.keys(data);
            const payload = {};
            let formDataKeyFieldValue: string = '';
            fields.forEach((field: string) => {
                if (data[field] instanceof File) {
                    payload[field] = data[field].name;
                } else {
                    payload[field] = data[field];
                }

                if (form.formKeyFieldName === field) {
                    formDataKeyFieldValue = data[field];
                }
            });
            showSnackbar('Saved Successfully', 'success', 5000);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('saveFormData: ', error);
            showSnackbar('Failed to save the data', 'error', 5000);
        }
    };

    const loadPage = async () => {
        if (formData) {
            if (formData.isDraft) {
                setData(formData.formDataJson);
                setInitialData(formData.formDataJson);
            } else {
                await getFormDataDetail();

            }
        }
        await getFormSchemaDetail();
        setShowJsonForms(true);
    };

    useEffect(() => {
        loadPage();
    }, []);

    useEffect(() => {
        const saveData = () => {
            if (data && !deepEqual(data, initialData)) {
                saveFormData();
            }
        };

        const debounceSave = setTimeout(saveData, 3000);

        return () => {
            clearTimeout(debounceSave);
        };
    }, [data, initialData]);


    const onChange = ({ data, errors }) => {
        setData(data);
        setErrors(errors);
    };

    const deepEqual = (obj1, obj2) => {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    };

    return (
        <div>
            <Dialog
                fullScreen
                open={showDialog}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            {form.formName}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Grid
                    container
                    justifyContent={'center'}
                    spacing={1}
                    className={classes.container}
                >
                    {loading && <ShoFormLoader />}
                    <Grid item sm={8} className={classes.jsonFormsContainer}>
                        <Typography variant={'h6'} className={classes.title}>
                            {form.formName}
                        </Typography>
                        <div className='sho-form-container'>
                            {showJsonForms && <JsonForms
                                schema={schema}
                                uischema={uischema}
                                data={data}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={onChange}
                            />}
                        </div>
                        {!loading && formData?.formDataId > 0 && (
                            <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                                <Button
                                    onClick={handleSubmit}
                                    color='primary'
                                    variant='contained'
                                >
                                    Submit Survey Response
                                </Button>
                            </Stack>
                        )}
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
}

export default PublicAddEditFormEntry;
