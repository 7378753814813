import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  SvgIcon,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import SecurityIcon from '@mui/icons-material/Security';
import { Theme } from '@mui/material/styles';
import { SIDE_NAV_WIDTH, TOP_NAV_HEIGHT } from './consts';
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SyncIcon from '@mui/icons-material/Sync';
import { Logout } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { RootState } from '../../store/store';
import Typography from '@mui/material/Typography';
import { syncData } from '../../hooks/useNetworkSync';
import indexedDBService from '../../services/indexdb-service';
import { showSnackbar } from '../../services/snackbar.service';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../services/config.service';
import { Browser } from '@capacitor/browser';
import { Capacitor, CapacitorCookies } from '@capacitor/core';

export const TopNav = (props) => {
  const { onNavOpen } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { logout } = useAuth0();
  const user = useSelector((state: RootState) => state.userDetail);

  const navigate = useNavigate();

  const onLogout = async () => {
    handleProfileMenuClose();
    let currentLogo: string = localStorage.getItem("companyLogoUrl") ?? ""
    let currentColor: string = localStorage.getItem("formsPrimaryColor") ?? ""
    let currentLogo64: string = localStorage.getItem("logoBase64") ?? ""
    localStorage.clear();
    await indexedDBService.deleteItem('General', 'User')
    localStorage.setItem("companyLogoUrl", currentLogo)
    localStorage.setItem("formsPrimaryColor", currentColor)
    localStorage.setItem("logoBase64", currentLogo64)
    await doLogout();
  };

  const doLogout = async () => {

    if (Capacitor.isNativePlatform()) {
      await logout({
        logoutParams: {
          returnTo: appConfig.AUTH0_REDIRECTURI + 'login'
        },
        async openUrl(url) {
          // Redirect using Capacitor's Browser plugin
          await Browser.open({
            url,
            windowName: "_self"
          });
        }
      });
    }
    else {
      logout({ logoutParams: { returnTo: window.location.origin + '/login' } });
    }
  };

  const onViewProfile = () => {
    navigate('/profile');
  }

  const getInitials = () => {
    return user.name?.split('.').map((n) => n[0]).join('').toUpperCase();
  };

  const [profileAnchorEl, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);
  const openProfileMenu = Boolean(profileAnchorEl);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  }

  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState<null | HTMLElement>(null);
  const openNotificationMenu = Boolean(notificationAnchorEl);
  const handleNoticationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(event.currentTarget);
  };
  const handleNoticationMenuClose = () => {
    setNotificationAnchorEl(null);
  }

  const [badgeCount, setBadgeCount] = useState(0);
  const assignBadgeCount = async () => {
    const storedData = await indexedDBService.getItem('Sync', 'AddUpdateFormData');
    if (storedData && storedData.length > 0) {
      const currentUserData = storedData.filter(x => x.data.customerId === user?.customerId);
      setBadgeCount(currentUserData.length);
    } else {
      setBadgeCount(0);
    }
  }

  const [isSyncing, setIsSyncing] = useState(false);

  const onSyncPendingChanges = async () => {
    if (navigator.onLine) {
      setIsSyncing(true); // Disable the button
      try {
        await syncData();
      } catch (error) {
        showSnackbar('Sync failed. Please try again.', 'error', 5000);
      } finally {
        setIsSyncing(false); // Re-enable the button
      }
    } else {
      showSnackbar('To sync your changes, please ensure that you are connected to the internet.', 'error', 5000);
    }
  };

  // Perform any setup that requires access to the DOM
  useEffect(() => {
    assignBadgeCount();

    const channel = new BroadcastChannel('sho-forms-channel');
    channel.addEventListener('message', (event) => {
      assignBadgeCount();
    });
  }, []);

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: (theme) => appConfig.THEME_COLORS.PRIMARY,
          position: 'sticky',
          left: {
            lg: `${SIDE_NAV_WIDTH}px`
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
          },
          zIndex: (theme) => theme.zIndex.appBar
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            {!lgUp && (
              <IconButton className='color-white' onClick={onNavOpen}>
                <SvgIcon fontSize="medium">
                  <MenuIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >

            <Avatar
              onClick={handleProfileMenuOpen}
              style={{ color: "white", backgroundColor: "#ff5722" }}
              sx={{
                cursor: 'pointer',
                height: 40,
                width: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                typography: 'body2'
              }}
            >
              {getInitials()}
            </Avatar>

            <Badge sx={{ cursor: 'pointer' }} onClick={handleNoticationMenuOpen} badgeContent={badgeCount} color="warning">
              <NotificationsIcon color="action" style={{ color: "#fff" }} />
            </Badge>

            <Tooltip title="Logout">
              <IconButton style={{ color: "white" }} onClick={onLogout}>
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>

        <Menu
          anchorEl={profileAnchorEl}
          id="account-menu"
          open={openProfileMenu}
          onClose={handleProfileMenuClose}
          onClick={handleProfileMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleProfileMenuClose}>
            <Avatar sx={{ bgcolor: "#ff5722", color: "#fff" }}  >
              {getInitials()}
            </Avatar> {user?.name}
          </MenuItem>
          <MenuItem onClick={handleProfileMenuClose}>
            <Avatar sx={{ bgcolor: "#1e88e5", color: "#fff" }}  >
              <EmailIcon />
            </Avatar> {user?.email}
          </MenuItem>
          <MenuItem onClick={handleProfileMenuClose}>
            <Avatar sx={{ bgcolor: "#ff5722", color: "#fff" }}  >
              <SecurityIcon />
            </Avatar> {user?.roleName}
          </MenuItem>
          <Divider />
          <MenuItem onClick={onViewProfile}>
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
          <MenuItem onClick={onLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={notificationAnchorEl}
          id="account-menu"
          open={openNotificationMenu}
          onClose={handleNoticationMenuClose}
          onClick={handleNoticationMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleProfileMenuClose}>
            <Typography variant="subtitle1" gutterBottom>
              Total {badgeCount} pending change(s).
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem >
            <Button onClick={onSyncPendingChanges} disabled={isSyncing || badgeCount === 0} variant="contained" size="small">
              <SyncIcon fontSize="small" /> {isSyncing ? 'Syncing...' : 'Sync Pending Changes'}
            </Button>
          </MenuItem>
        </Menu>

      </Box>
    </>
  );
};
