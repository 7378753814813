import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { ControlTypeInfo } from '../../types/form-builder';

const ControlType: React.FC<{ controlType: ControlTypeInfo }> = ({ controlType }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: controlType.id.toString(),
        data: { controlType },
    });

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        width: '200px',
        height: '50px',
        cursor: 'pointer',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10px',
        backgroundColor: '#B24D49',
        color: 'white',
        fontWeight: 'bold',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        transition: 'background-color 0.3s',
    };

    return (
        <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
            {controlType.name}
        </div>
    );
};

export default ControlType;