export enum RoleEnum {
    SuperAdmin = 1,
    ShoAdmin = 2,
    ShoUser = 3,
    CustomerAdmin = 4,
    CustomerUser = 5
}

export const Role = {
    SuperAdmin: 1,
    ShoAdmin: 2,
    ShoUser: 3,
    CustomerAdmin: 4,
    CustomerUser: 5
}

export const PageEnum = {
    MyForms: "MyForms",
    FormEntires: "FormEntires",
    FormBuilder: "FormBuilder",
    FormBuilderNew: "FormBuilderNew",
    FormSettings: "FormSettings",
    Users: "Users",
    FormGroups: "FormGroups",
    RoleManagement: "RoleManagement",
    ExcelDemo: "ExcelDemo",
    Customers: "Customers",
}

export enum Control {
    Text = 1,
    Integer,
    SingleSelect,
    MultiSelect,
    Image,
    Binary,
    Object,
    Array,
    DateTime,
    Date,
    Time,
    Email, // Everything after this line is custom
    Calculation
}

export enum BinaryEnum {
    Yes = 1,
    No,
    NA
}

export enum CalculateOperation {
    Count = 1,
    Sum,
    Multiply,
    Divide
}

